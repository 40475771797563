.loading {
	position: fixed;
	display: flex;
	width: 100%;
	height: 100%;
	flex: 1;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	z-index: 10;
}

.loading-logo {
	position: relative;
	width: 95px;
	height: 60px;
	margin: 20px 0 10px;
}

.loading-logo::before, .loading-logo::after {
	position: absolute;
	display: block;
	width: 60px;
	height: 60px;
	content: '';
	top: 0;
	background-color: rgba(178,45,64,0.65);
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	animation-duration: 1s;
	animation-iteration-count: infinite;
}

.loading-logo::before {
	left: 0;
	animation-name: loadingLeft;
}

.loading-logo::after {
	right: 0;
	animation-name: loadingRight;
}

@keyframes loadingLeft {
	0% { left: 0; }
	100% { left: 36px; }
}

@keyframes loadingRight {
	0% { right: 0; }
	100% { right: 36px; }
}