.login-container {
	position: fixed;
	display: flex;
	width: 100%;
	height: 100%;
	padding: 15px;
	flex: 1 1;
	/*flex-direction: column;*/
	background: #961e35;
	background: linear-gradient(-10deg, rgba(60,7,17,1) 0%, rgba(150,30,53,1) 100%);
	align-items: center;
	justify-content: center;
	overflow: auto; 
}

.login-container .alert {
	margin-top: -10px;
	margin-bottom: -40px;
	border-left: 0;
	border-right: 0;
	border-radius: 0;
}

.login-content {
	width: 100%;
	max-width: 400px;
	padding: 80px 0;
	background: #fff;
	border-radius: 4px;
	box-shadow: 0 0 0px 5px rgba(0,0,0,0.2);
}

.logo {
	display: block;
	height: 80px;
	margin: 0 auto 10px;
}

.form-login {
	width: 100%;
	max-width: 330px;
	padding: 15px;
	margin:  40px auto 0;
	text-align: center;
}

.form-login .form-group {
	margin-bottom: 0;
}

.form-login .form-control {
	position: relative;
	box-sizing: border-box;
	height: auto;
	padding: 10px;
	font-size: 16px;
}

.form-login #usuario, .input-group-usuario>.input-group-prepend>.input-group-text {
	margin-bottom: -1px;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.form-login .form-control:focus {
	z-index: 2;
}

.form-login #senha, .input-group-senha>.input-group-prepend>.input-group-text {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.form-login .btn {
	margin-top: 20px;
}

.login-container .copyright {
	margin-top: 50px;
	text-align: center;
	font-size: 14px;
	color: #fff;
}

@media (max-height: 433px) {
	.login-container {
		align-items: flex-start;
	}
}
.loading {
	position: fixed;
	display: flex;
	width: 100%;
	height: 100%;
	flex: 1 1;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	z-index: 10;
}

.loading-logo {
	position: relative;
	width: 95px;
	height: 60px;
	margin: 20px 0 10px;
}

.loading-logo::before, .loading-logo::after {
	position: absolute;
	display: block;
	width: 60px;
	height: 60px;
	content: '';
	top: 0;
	background-color: rgba(178,45,64,0.65);
	border-radius: 50%;
	animation-duration: 1s;
	animation-iteration-count: infinite;
}

.loading-logo::before {
	left: 0;
	animation-name: loadingLeft;
}

.loading-logo::after {
	right: 0;
	animation-name: loadingRight;
}

@keyframes loadingLeft {
	0% { left: 0; }
	100% { left: 36px; }
}

@keyframes loadingRight {
	0% { right: 0; }
	100% { right: 36px; }
}
body {
	margin: 0;
	padding: 0;
	font-family: sans-serif;
}

